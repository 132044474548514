.App {
  /*overflow-y: scroll;*/
  -webkit-overflow-scrolling: touch;
}

* { -moz-box-sizing: border-box; -webkit-box-sizing: border-box;
box-sizing: border-box; }

.inner {
  display: flex;
  max-width: 1000px;
  min-height: 58px;
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
}

body {
  font-family: 'Graphik Web', Helvetica, Arial, sans-serif;
}

/* Mobile First Optimization*/

@media all and (max-width: 730px) {
  /* Header */
  header .inner {
    flex-direction: column;
  }
  .top-menu ul {
    flex-wrap: wrap;
    width: 100%;
  }
  .top-menu li {
    flex: 1 1 50%;
  }
  .logo {
    padding: 20px;
    justify-content: center;
  }
  /* navbar */
  .navPlatforms {
    /*display: none !important;*/
  }
  .navPlatforms ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .navPlatforms li {
    flex: 1 1 20%;
  }

  /*Section4 TaskManagement*/
  .section4 {
    height: auto !important;
  }
  .section4 h2, p {
    width: auto !important;
  }
  .section4 .tasksUL {
    display: flex;
    flex-wrap: wrap;
  }
  .section4 .tasksLI {
    flex: 1 1 20%;
  }

  /*Section2-3*/
  .Default-Section .section2, .Default-Section .section3 {
    /*align-items: center !important;*/
  }
  .section2 div, .section3 div {
    justify-content: center !important;
    padding-right: 0 !important;
  }
  .section2 p, .section3 p {
    height: auto !important;
    padding-bottom: 25px !important;
  }

  /*Footer*/
  .socialMediaSection .container {
    padding: 0;
  }
  .mediaWrapper ul {
    flex-wrap: wrap;
  }
  .mediaWrapper ul li {
    flex: 1 1 30%;
    margin: 0px !important;
    margin-top: 10px !important;
  }
  .mediaWrapper ul li a {
    padding: 0 !important;
  }
}

@media all and (max-width: 400px) {
  /* Header */
  .top-menu li {
    flex-basis: 100%;
  }
  header .inner {
    padding: 0 !important;
  }
  /* Sections */
  .section1 {
    /*background-color: rgba(0, 0, 0, 0.6);*/
  }
  .section1 h1 {
    font-size: 28px !important;
    text-align: center;
  }
  .section1 > * {
    display: flex;
    justify-content: center;
  }

  .section3 .shadow {
    height: 300px;
  }

  .section5 .article-wrapper {
    display: flex !important;
    flex: wrap !important;
    flex-direction: column !important;
    flex: 1 1 100% !important;
  }

  #frame {
    display: none !important;
  }
}

@media all and (max-width: 540px) {
  /* Footer */
  .footer {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
  .footer .container {
    min-width: 200px;
  }
  .navPlatforms .inner {
    padding: 0 !important;
  }

  /* Modals */
  .modal-content {
      margin: 20% auto 15% auto !important; /* 5% from the top, 15% from the bottom and centered */
      width: 100% !important; /* Could be more or less, depending on screen size */
      overflow: scroll !important;
      max-height: 350px !important;
  }
}
