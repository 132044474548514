/* default template of all sections */
.Default-Section {
  height: 600px;
  background-color: #fff;

  display: flex;
  align-items: center;
}

.Default-Section .inner {
  color: #fff;
  height: 340px;
  padding: 0px 25px;
  display: flex;
  flex-wrap: wrap;
  /*align-items: flex-start;*/

  /* Development */
  /* background-color: black;
     opacity: 0.4; */
}

.Default-Section .inner div {
  /*works on all sections
  where elements are grouped
  in their parent div*/
  flex: 1 1 100%;
}

.Default-Section .inner div p {
  margin: 0;
  padding: 0;
  max-width: 420px;
}

.shadow {
  border: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 1);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

:root {
  --items-head-color: #424244;
  --items-desc-color: #767e80;
  --line-head-height: 1.6;
  --line-p-height: 21px;
  --font-p-size: 14px;
  --font-sub-size: 18px;
}

.sub-header {
  line-height: var(--line-head-height);
  font-size: var(--font-sub-size);
  color: var(--items-desc-color);
  padding-top: 15px;
}

/*Following applies to the .inner of a section*/
/*To target the parent container use inline styles in Sections.js*/

/* Agenda:
   Section1 -> Pic      - flex-order: 1
   Section2 -> Pic      - flex-order: 4
   Section3 -> Pic      - flex-order: 5
   Section4 -> Tasks    - flex-order: 3
   Section5 -> Articles - flex-order: 6
   0 - header, 2 - Navbar, 7 - Footer
*/

/* Section 1 inner styles */
.Default-Section .section1 {
  height: 420px;
}
.section1 h1 {
  text-shadow: 3px 2px 4px #000000;
  margin: 0;
  font-size: 34pt;
  font-weight: 100;
  max-width: 475px;
}
.section1 h2 {
  text-shadow: 2px 2px 4px #000000;
  margin: 0;
  font-size: 18pt;
  font-weight: 100;
  max-width: 450px;
}
/* replaced by btn component
.section1 button {
  display: block;
  min-width: 100px;
  background-color: rgba(163, 2, 2, 0.6);
  color: #ffff;
  text-align: center;
  padding: 5px 0px;
  border-radius: 5px;
  border: 0px solid #ffff;
  cursor: pointer;
  transition: all 0.5s;
}
.section1 button:hover {
  background-color: rgba(163, 2, 2);
} */

/* Section 2 inner styles */
/* overriding the default inner*/
.Default-Section .section2 {
  align-items: flex-start;
}
.section2 div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-right: 65px;
}
.section2 div h1 {
  flex-basis: 100%;
  text-align: left;
}
.section2 .cont {
  max-width: 480px;
}

/* Section 3 inner styles */
.section3 div {
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.section3 .cont p {
  min-height: 75px;
}

/* Section 4 inner */
.Default-Section .section4 {
  display: flex;
  /*min-height: 380px;*/
  height: auto;
  align-items: center;
}
.Default-Section .section4 div {
  text-align: center;
}
.Default-Section .section4 div p {
  max-width: 100%;
  width: 600px;
  margin: 0 auto;
  color: var(--items-desc-color);
}
.section4 h2 {
  color: var(--items-head-color);
}
.tasksUL {
  display: flex;
  list-style-type: none;
  padding: 0px;
  justify-content: space-around;
  flex-wrap: wrap;
}
.tasksLI {
  /*wrap should be active*/
  flex-direction: column;
  padding: 20px;
  flex: 1 1 33%;
  color: #de4a32;
  display: flex;
  align-items: center;
}
.tasksLI .desc {
  padding: 15px;
}

/* Section 5 inner */
.Default-Section .section5 {
  /*Applies to .inner*/
  height: auto;
}
.Default-Section .section5 div {
  text-align: center;
}
.section5 h2, h3 {
  color: var(--items-head-color);
}
.Default-Section .section5 div > p {
  max-width: 100%;
  width: 600px;
  padding-bottom: 45px;
  margin: 0 auto;
  color: var(--items-desc-color);
}
.Default-Section .section5 div > div p {
  max-width: 100%;
  padding: 0px 25px;
  padding-bottom: 25px;
  color: var(--items-desc-color);
  line-height: var(--line-p-height);
  font-size: var(--font-p-size);
}

.section5 .article-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.section5 article {
  flex: 1 1 33%;
}


/*Mobile MockUp*/
#wrap {
  background-color: white;
  width: 288px;
  height: 505px;
  padding: 0;
  overflow: hidden;
}
#frame {
  width: 200px;
  height: 400px;
  display: inline-block;
  box-sizing: border-box;
  background-size: contain;
  padding: 60px 19px 78px 19px;
  vertical-align: middle;
  border: 1px solid transparent;
}


#frame {
  opacity: .6;
  -ms-zoom: 0.25;
  -moz-transform: scale(0.25);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.25);
  -o-transform-origin: 0 0;
  /* Change size here */
  -webkit-transform: scale(0.25);
  -webkit-transform-origin: 0 0;
  vertical-align: middle;
  transition: all 0.5s;
}
#wrap {
  vertical-align: middle;
  -ms-zoom: 0.55;
  -moz-transform: scale(0.55);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.55);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.55);
  -webkit-transform-origin: 0 0;
}
#frame:hover {
  opacity: 1;
  margin-top: -200px;
  position: absolute;
  -ms-zoom: 1.2;
  transform-origin: left;
  -webkit-transform: scale(1.2);
  z-index: 999;
}
