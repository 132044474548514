.container {
  min-height: 140px;
  padding: 0 40px;
  max-width: 960px;
}

/* Social Icons */
.socialMediaSection {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: rgb(36, 36, 36);
  min-height: 150px;
  padding: 50px;
  padding-bottom: 10px;
}
.mediaWrapper {
  text-align: center;
  color: #919191;
}
.mediaWrapper h2 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}
.mediaWrapper ul {
  display: flex;
  justify-content: center;
  width: 100%;
  list-style-type: none;
  padding: 0;
}
.mediaWrapper ul li {

}
.socialLink {
  display: block;
  padding: 15px;
  opacity: .5;
}

.socialLink:hover {
  opacity: 1;
}

/*Footer menues*/
.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #343434;
  min-height: 250px;
}
/* container as child of Footer
   for flex-basis */
.menu {
  margin-bottom: 0px;
  padding: 0, 15px;
  max-width: 150px;
}
.menu h2 {
  color: #c4c4c4;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.35;
  margin-top: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.menu ul {
  font-size: 14px;
  letter-spacing: 0.3px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.menu ul li {
  padding-bottom: 4px;
}
.menu ul li a {
  color: #919191;
  text-decoration: none;
}
.menu ul li a:hover {
  color: rgba(250, 250, 250, 0.8);
  text-decoration: underline;
}


/* Inline Styling in any case */
/*
//https://blog.spoongraphics.co.uk/tutorials/how-to-give-your-photos-a-dark-processed-lomo-effect
const styles = {

  socialMediaSection: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: 'rgb(36, 36, 36)',
    minHeight: '150px',
    padding: 50,
    paddingBottom: 10
  },
  mediaWrapper: {
    textAlign: 'center',
    color: '#919191'
  },
  socialTitle: {
    margin: 0,
    padding: 0,
    fontSize: '20px'
  },
  socialListUL: {
    display: 'flex',
    justifyContent: 'center',
    width: 100,
    listStyleType: 'none',
    padding: 0
  },
  socialListItem: {
  },
  socialLink: {
    display: 'block',
    padding: '15px',
    opacity: '.5'
  },


  footer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    backgroundColor: '#343434',
    height: 250
  },
  container: {
    height: '140px',
    padding: '0 40px',
    maxWidth: '960px'
  },
  menu: {
    marginBottom: '0px',
    padding: '0, 15px',
    maxWidth: '150px'
  },
  footMenuTitle: {
    color: '#c4c4c4',
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '1.35',
    marginTop: '30px',
    marginBottom: '15px',
    textTransform: 'uppercase'
  },
  footMenuUL: {
    fontSize: '15px',
    listStyleType: 'none',
    margin: 0,
    padding: 0
  },
  footMenuLI: {
    paddingBottom: '4px'
  },
  link: {
    color: '#919191',
    textDecoration: 'none'
  }
}
*/
