.logo {
  display: flex;
  align-items: center;
  min-width: 92px;
  min-height: 58px;
  flex: 2;
}

.top-menu {
  flex: 1;
}

header .inner {
  padding: 0 25px;
}

header ul {
  display: flex;
  width: 450px;
  margin: 0;
  padding: 0;
  list-style: none;
}

header li {
  flex: 1;
  cursor: pointer;
  /*  border: 1px solid black;*/
}

header .top-menu a {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 11pt;
  font-weight: 600;
  letter-spacing: 1px;
  border-bottom: 2px solid transparent;
  border-top: 1px solid transparent;
  color: rgba(43, 43, 43, 0.92);
  text-decoration: none;
  padding: 20px 5px;
  transition: all 0.5s;
}

header .top-menu a:hover {
  border-color: #db4c3f;
  background-color: #f6f6f6
}



/* Login panel */
input[type=text], input[type=password],
input[type=email] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}
/* Set a style for all buttons */
.login {
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
}
.login:hover, .cancelbtn:hover {
    opacity: 1;
}
/* Extra styles for the cancel button */
.cancelbtn {
    width: auto;
    margin-top: 10px;
    padding: 10px 18px;
    color: white;
    background-color: #f44336;
    cursor: pointer;
    opacity: 0.9;
}
/* Center the image and position the close button */
.imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
    position: relative;
}
img.avatar {
    width: 50%;
    border-radius: 0%;
}
.container {
    padding: 16px;
}
.container-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  padding-left: 40px;
  height: 105px;
}
span.psw {
    float: right;
    padding-top: 16px;
}
/* The Close Button (x) */
.close {
    position: absolute;
    right: 25px;
    top: 0;
    color: #000;
    font-size: 35px;
    font-weight: bold;
}
.close:hover,
.close:focus {
    color: red;
    cursor: pointer;
}

/* SignUP panel */
/* Add a background color when the inputs get focus */
input[type=text]:focus, input[type=password]:focus,
input[type=email]:focus {
    background-color: #ddd;
    outline: none;
}
/* Float cancel and signup buttons and add an equal width */
.sign-cancel-btn, .signupbtn {
  float: left !important;
  width: 50% !important;
  margin-bottom: 30px !important;
}
/* Set a style for all buttons */
.sign-modal button {
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
}
.sign-modal button:hover {
    opacity:1;
}
/* Extra styles for the cancel button */
.sign-cancel-btn {
    padding: 14px 20px !important;
    background-color: #f44336 !important;
}
/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
    border: 1px solid #888;
    width: 60%; /* Could be more or less, depending on screen size */
}
hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
}
/* Clear floats */
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
    span.psw {
       display: block;
       float: none;
    }
    .cancelbtn, .signupbtn, .sign-cancel-btn {
       width: 100%;
    }
}
