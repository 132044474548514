.navPlatforms {
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dedede;
  font-size: .001px;
}

.navPlatforms .inner {
  justify-content: center;
  max-width: 1000px;
  padding: 0 25px;
}

.navPlatforms div ul {
  padding: 0px;
  padding-top: 10px;
}

.navPlatforms div ul li {
  display: inline-block;
  height: 66px;
  width: 65px;
  padding: 0px 0 0 0;
  position: relative;
  vertical-align: top;
}

.navPlatforms div ul li a {
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
  cursor: pointer;
  display: table;
  height: 100%;
  opacity: .5;
  width: 100%;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  margin-top: 0px;
}

.navPlatforms div ul li a:hover {
  opacity: 1;
  border-bottom: 2px solid #de4a32;
}

.platforms-icon {
  display: flex;
  justify-content: center;
  color: var(--items-desc-color); /* #7d8485 */
  font-size: 18px;
  vertical-align: middle;
}

.platforms-name {
  color: var(--items-desc-color);
  display: table-row;
  font-size: 11px;
  line-height: 1.1;
  text-align: center;
  white-space: normal;
}

/* for inline styling */
/*
var styles = {
  navPlatforms: {
    height: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #dedede',
    fontSize: '.001px'
  },
  platformsItems: {
    padding: 0,
    paddingTop: 7
  },
  platformsItem: {
    display: 'inline-block',
    height: 66,
    width: 65,
    padding: '5 0 0 0',
    position: 'relative',
    verticalAlign: 'top',
    borderBottom: '2px solid transparent'
  },
  platformsAction: {
    WebkitTransition: 'opacity .2s ease-in',
    transition: 'opacity .2s ease-in',
    cursor: 'pointer',
    display: 'table',
    height: '100%',
    opacity: '.5',
    width: '100%',
    textDecoration: 'none'
  },
  platformsActionHover: {
    opacity: 1,
    display: 'hidden',
    textDecoration: 'none'
  },
  platformsIcon: {
    display: 'flex',
    justifyContent: 'center',
    color: '#7d8485',
    fontSize: 18,
    height: 0,
    verticalAlign: 'middle'
  },
  platformsName: {
    color: '#7d8485',
    display: 'table-row',
    fontSize: 11,
    lineHeight: 1.1,
    textAlign: 'center',
    whiteSpace: 'normal'
  }
};
*/
