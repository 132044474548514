.portfolio-section {
  width: 100%;
  border-bottom: 1px solid #dedede;
  min-height: 600px;
  height: auto;
  align-items: flex-start !important;
}

.portfolio ul {
  list-style: none;
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* phone spacing */
  margin: 0;
  padding: 0;
}

.portfolio li {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1 33%;
  padding: 10px 10px 20px 10px;
}

.portfolio li div {
  padding-bottom: 10px;
}

.phone {
  margin: 0 auto;
  width: 330px;
  height: 403px;
  display: flex;
  box-sizing: border-box;
  background-size: contain;
  padding: 59px 19px 78px 19px;
  vertical-align: middle;
  border: 1px solid transparent;
  background-repeat: no-repeat;

  -ms-zoom: 1.0;
  -moz-transform: scale(1.0);
  -moz-transform-origin: 0 0;
  -o-transform: scale(1.0);
  -o-transform-origin: 0 0;
  /* Change size here */
  -webkit-transform: scale(1.0);
  -webkit-transform-origin: 0 0;
  vertical-align: middle;
  transition: all 0.5s;
}
.wrap {
  background-color: #ffffff;
  width: 100%;
  height: 506px;
  padding: 0;
  overflow: hidden;

  vertical-align: middle;
  -ms-zoom: 0.56;
  -moz-transform: scale(0.56);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.56);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.56);
  -webkit-transform-origin: 0 0;
}

.div-wrapper {
  width: 215px;
  overflow: hidden;
  margin: 0 auto;
}

.android-phone {
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 340px; /*width: 200*/
  height: 440px;
  display: flex;
  box-sizing: border-box;
  background-size: contain;
  padding: 23px 19px 78px 4px;
  vertical-align: middle;
  border: 1px solid transparent;
  -ms-zoom: 1.0;
  -moz-transform: scale(1.0);
  -moz-transform-origin: 0 0;
  -o-transform: scale(1.0);
  -o-transform-origin: 0 0;
  /* Change size here */
  -webkit-transform: scale(1.0);
  -webkit-transform-origin: 0 0;
  vertical-align: middle;
  transition: all 0.5s;
}
.android-wrap {
  background-color: #ffffff;
  border-radius: 25px;
  width: 100%; /* width: 288 */
  height: 612px; /* width: 567 */
  padding: 0;
  overflow: hidden;
  vertical-align: middle;
  -ms-zoom: 0.55;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.65);
  -webkit-transform-origin: 0 0;
}

div.gallery {
    margin: 5px;
    border: 1px solid rgb(200, 200, 200);
    float: left;
    width: 260px;
    height: 340px;
    /* width: 100%;
    height: 100%; */
    display: flex;
    flex-direction: column;
}

.gallery a, .portfolio a, .sub-header a {
  text-decoration: none;
}

div.gallery-foot {
  display: flex;
  max-height: 40px;
  align-items: center;
  justify-content: center;
}

div.gallery:hover {
    border: 1px solid #777;
}

div.gallery img {
    width: 100%;
    height: auto;
}

div.desc {
    padding: 15px;
    text-align: center;
}

/* react-transition-group */
.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}
